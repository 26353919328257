import React from 'react';
import { bool } from 'prop-types';
import { SidebarMenu, SidebarLink, SidebarRoute } from './styled';

const Sidebar = ({open}) => (
  <>
    <SidebarMenu open={open}>
      <SidebarLink 
        to="about"
        smooth={true}
        duration={500}
        spy={true}
        exact='true'
        offset={-150}
      >
        O nas
      </SidebarLink>
      <SidebarLink 
        to="products"
        smooth={true}
        duration={500}
        spy={true}
        exact='true'
        offset={-150}
      >
        Produkty
      </SidebarLink>
      <SidebarRoute to='/kontakt'>Kontakt</SidebarRoute>
    </SidebarMenu>
  </>
);

Sidebar.propTypes = {
  open: bool.isRequired,
};

export default Sidebar;