import styled from "styled-components";

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 100%;
  padding: 36px 0;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};

  &:nth-child(2n) {
    flex-direction: column;
  }

  &:last-child {
    border-bottom: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    flex-direction: row;

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }
  }
`;

export const ProductsSmallTitle = styled.div`
  margin-bottom: 15px;
  text-transform: uppercase;
`;

export const ProductsTitle = styled.h2`
  text-align: center;

  &:after {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
`;

export const ProductTitle = styled.h3`
  display: flex;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryMainBlue};
`;
export const ProductInfo = styled.div`
  width: 100%;

  p {
    padding: 10px 0;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    svg {
      height: 25px;
      margin-right: 10px;
      color: ${({ theme }) => theme.primaryBlue};
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    width: 50%;
  }
`;

export const ProductImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-blend-mode: multiply;
`;

export const BtnWrapper = styled.div`
  margin: 10px 0;
`;

export const ProductPDF = styled.a`
  height: 20px;
  display: inline-block;
  text-decoration: none;
  padding: 10px 15px;
  margin: 10px 10px 10px 0;
  border-radius: 25px;
  color: ${({ theme }) => theme.primaryWhite};
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  background: ${({ theme }) => theme.primaryMainBlue};
  /* box-shadow: 0 4px 16px 2px rgb(7 5 139 / 10%); */

  &:hover {
    color: ${({ theme }) => theme.primaryMainBlue};
    background: transparent;
    border: 1px solid ${({ theme }) => theme.primaryMainBlue};
  }

  svg {
    height: 100%;
    margin-right: 10px;
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  padding: 36px 0 0 0;
`;

export const ProductSlider = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    width: 50%;
  }
`;
