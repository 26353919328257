import React, {useEffect, useRef} from "react";
import { useSpring, animated } from "react-spring";
import {
  HeaderContainer,
  HeaderTitle,
  HeaderH1,
  HeaderBG,
  HeaderBtn,
  HeaderVideoBG,
} from "./styled";
import backgroundVideo from "../../assets/filmBG.mp4";
import { ArrowDownS } from "@styled-icons/remix-line/ArrowDownS";

const Header = () => {
  const ref = useRef();
  const [{ offset }, set] = useSpring(() => ({ offset: 0 }));
  const calcTranslateY = o => `translateY(${o * 0.12}px)`;
  const titleArray = "Maseczki\xa0medyczne".split("");
  
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1800
  })

  const handleScroll = () => {
    const posY = ref.current.getBoundingClientRect().top;
    const offset = window.pageYOffset - posY;
    set({ offset });
  };


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <>
      <HeaderBG ref={ref}>
        <HeaderContainer >
          <HeaderVideoBG
            preload
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
          >
            <source src={backgroundVideo} type="video/mp4" />
          </HeaderVideoBG>
          <animated.div 
             style={{
              position: "absolute",
              top: "38%",
              transform: offset.to(calcTranslateY)
            }}
          >
            <HeaderTitle>{titleArray.map((item, index) => (
              <span key={index}>{item}</span>
            ))}</HeaderTitle>
            <HeaderH1 style={fade}>Certyfikowana produkcja maseczek w Polsce.</HeaderH1>
            <HeaderBtn
              style={fade}
              to="about"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-70}
            >
              <ArrowDownS />
              Dowiedz się więcej
            </HeaderBtn>
          </animated.div>
        </HeaderContainer>
      </HeaderBG>
    </>
  );
};

export default Header;
