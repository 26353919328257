import styled from "styled-components";

const AppContainer = styled.div`
  max-width: 1100px;
  width: auto;
  height: 100%;
  margin: 0 auto;
  padding: 0 24px;

  @media screen and (max-width: ${({ theme }) => theme.mobile}) {
    
  }
`;

export default AppContainer;