import React from "react";
import AppContainer from "../Container";
import { useRouteMatch } from "react-router-dom";
import {
  FooterContainer,
  FooterContent,
  FooterContactTitleWrapper,
  FooterContactTitle,
  PageBtn,
  FooterAddress,
  FooterContact,
  FooterContactsLine,
  IconStyleWrapper,
  Copyright,
} from "./styled";

import { PhoneAlt } from "@styled-icons/fa-solid/PhoneAlt";
import { Mail } from "@styled-icons/heroicons-solid/Mail";
import { Location } from "@styled-icons/entypo/Location";

const Footer = () => {
  const isContactPage = useRouteMatch("/kontakt");

  return (
    <>
      <FooterContainer>
        <AppContainer>
          <FooterContent>
            <FooterContactTitleWrapper>
              <FooterContactTitle>Potrzebujesz pomocy?</FooterContactTitle>
              {isContactPage ? (
                <p>
                  Dowiedz się więcej:<PageBtn to="/">Home</PageBtn>
                </p>
              ) : (
                <p>
                  Skontaktuj się z nami:<PageBtn to="/kontakt">Kontakt</PageBtn>
                </p>
              )}
            </FooterContactTitleWrapper>
            <FooterAddress>
              <h3>Dane firmy:</h3>
              <p>
                POLANEX Sp. z o.o.
                <br />
                ul.Słoneczna 40
                <br />
                62-200 Gniezno
                <br />
                NIP: 784001050
              </p>
            </FooterAddress>
            <FooterContact>
              <h3>Kontakt:</h3>
              <FooterContactsLine href="tel:+48790208029">
                <IconStyleWrapper>
                  <PhoneAlt title="Phone" />
                </IconStyleWrapper>
                +48 790 208 029
              </FooterContactsLine>
              <FooterContactsLine href="mailto:sekretariat@polanex.com.pl">
                <IconStyleWrapper>
                  <Mail title="Mail" />
                </IconStyleWrapper>
                sekretariat@polanex.com.pl
              </FooterContactsLine>
            </FooterContact>
          </FooterContent>
          <Copyright>Copyright © 2023 POLANEX｜All rights reserved.</Copyright>
        </AppContainer>
      </FooterContainer>
    </>
  );
};

export default Footer;
