import React, { useState, useRef, useEffect, useLayoutEffect, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { bool, func } from "prop-types";
import Sidebar from "../Sidebar";
import BurgerWrapper from "../Burger";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import {
  Nav,
  NavbarConteiner,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLink,
  NavLinkRoute,
  NavBtn,
  NavBtnLang,
  NavMenuContact,
} from "./styled";


const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const node = useRef();
  const isContactPage = useRouteMatch("/kontakt");

  useOnClickOutside(node, () => setOpen(false));

  const changeNav = useCallback(() => {
    if (window.scrollY >= 70) {
      setIsScrolled(true);
    } else if (!isContactPage === false) {
      setIsScrolled(true);
    } else { 
      setIsScrolled(false);
    }
  },[isContactPage]);

  useLayoutEffect(() => {
    changeNav();
  }, [changeNav]);

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    return () => {
      window.removeEventListener("scroll", changeNav);
    };
  }, [changeNav]);

  
  return (
    <>
      <Nav isScrolled={isScrolled}>
        <NavbarConteiner>
            <NavLogo
              isScrolled={isScrolled}
              to="/"
            />
          {!isContactPage ? (
            <>
              <NavMenu>
                <NavItem key={1}>
                  <NavLink
                    isScrolled={isScrolled}
                    to="about"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-70}
                  >
                    O nas
                  </NavLink>
                </NavItem>
                <NavItem key={2}>
                  <NavLink
                    isScrolled={isScrolled}
                    to="products"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-70}
                  >
                    Produkty
                  </NavLink>
                </NavItem>
                <NavItem key={3}>
                  <NavLinkRoute isScrolled={isScrolled} to="/kontakt">
                    Kontakt
                  </NavLinkRoute>
                </NavItem>
                <NavBtn>
                  <NavBtnLang></NavBtnLang>
                </NavBtn>
              </NavMenu>
              <MobileIcon ref={node}>
                <BurgerWrapper open={open} setOpen={setOpen} />
                <Sidebar open={open} setOpen={setOpen} />
              </MobileIcon>
            </>
          ) : (
            <NavMenuContact>
              <NavItem key={1}>
                <NavLinkRoute isScrolled={isScrolled} to="/">Home</NavLinkRoute>
              </NavItem>
            </NavMenuContact>
          )}
        </NavbarConteiner>
      </Nav>
    </>
  );
};

BurgerWrapper.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Navbar;
