import React from "react";
import {
  AboutContainer,
  AboutTitleSmall,
  AboutTitle,
  AboutContent,
  AboutText,
  AboutTitleMobile,
  AboutTitleSmallMobile
} from "./styled";
import AppContainer from "../Container";
import production from "../../assets/about.jpg";

const About = () => (
  <>
    <AboutContainer name="about">
      <AppContainer>
        <AboutContent>
          <AboutTitleSmallMobile>Kim jesteśmy</AboutTitleSmallMobile>
          <AboutTitleMobile>Zaufaj naszej produkcji</AboutTitleMobile>
          <img src={production} alt="Production"></img>
          <AboutText>
            <AboutTitleSmall>Kim jesteśmy</AboutTitleSmall>
            <AboutTitle>Zaufaj naszej produkcji</AboutTitle>
            <p>
              Jesteśmy filią najstarszej w Polsce firmy szyjącej koszule –
              POLANEX Sp. z o.o
              , znajdującej się od 75-ciu lat w Gnieźnie. Nasze działania
              rozpoczęły się z inicjatywy Agencji Rozwoju Przemysłu i zostały
              przekazane do realizacji firmie POLANEX jako odpowiedź na czas
              pandemii i wynikających z niego wyjątkowych potrzeb, z którymi
              stykamy się na co dzień.
            </p>
          </AboutText>
        </AboutContent>
      </AppContainer>
    </AboutContainer>
  </>
);

export default About;
