import styled, { keyframes } from "styled-components";
import { animated } from "react-spring";
import { Link as LinkS } from "react-scroll";

export const HeaderBG = styled.div`
  height: 100vh;
  width: 100%;
`;

export const HeaderVideoBG = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

export const HeaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.3);
  }

  div {
    z-index: 1;
  }
`;

const animationTitle = keyframes`
  0% {opacity: 0;  }
  100% {opacity: 1; }
  /* 100% {opacity: 1; transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px); } */
  /* 100% {opacity: 0; transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); filter: blur(10px); } */
`;

export const HeaderTitle = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.primaryMainBlue};
  font-size: 45px;
  font-weight: 700;

  span {
    display: inline-block;
    opacity: 0;
    animation-name: ${animationTitle};
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
  span:nth-child(1) {
    animation-delay: 0.1s;
  }
  span:nth-child(2) {
    animation-delay: 0.15s;
  }
  span:nth-child(3) {
    animation-delay: 0.2s;
  }
  span:nth-child(4) {
    animation-delay: 0.25s;
  }
  span:nth-child(5) {
    animation-delay: 0.3s;
  }
  span:nth-child(6) {
    animation-delay: 0.35s;
  }
  span:nth-child(7) {
    animation-delay: 0.4s;
  }
  span:nth-child(8) {
    animation-delay: 0.45s;
  }
  span:nth-child(9) {
    animation-delay: 0.7s;
    display: block;
    height: 0;

    @media screen and (min-width: ${({ theme }) => theme.mobile}) {
      display: inline-block;
    }
  }
  span:nth-child(10) {
    animation-delay: 0.75s;
  }
  span:nth-child(11) {
    animation-delay: 0.8s;
  }
  span:nth-child(12) {
    animation-delay: 0.85s;
  }
  span:nth-child(13) {
    animation-delay: 0.9s;
  }
  span:nth-child(14) {
    animation-delay: 0.95s;
  }
  span:nth-child(15) {
    animation-delay: 1s;
  }
  span:nth-child(16) {
    animation-delay: 1.05s;
  }
  span:nth-child(17) {
    animation-delay: 1.1s;
  }
  span:nth-child(18) {
    animation-delay: 1.15s;
  }
`;

export const HeaderH1 = styled(animated.h1)`
  display: block;
  font-size: 28px;
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: 300;
`;

const animationBtn = keyframes`
  0% {opacity: 0; transform: translateY(100px) }
  100% {opacity: 1; transform: translateY(0px) }
  /* 100% {opacity: 1; transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px); } */
  /* 100% {opacity: 0; transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); filter: blur(10px); } */
`;

export const HeaderBtn = styled(LinkS)`
  display: flex;
  color: #ffffff;
  background: ${({ theme }) => theme.primaryMainBlue};
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 50px;
  font-size: 18px;
  margin: 20px auto;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primaryMainBlue};
  transition: 0.3s;
  padding-right: 15px;

  opacity: 0;
  animation-name: ${animationBtn};
  animation-duration: 1s;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  svg {
    height: 100%;
    color: ${({ theme }) => theme.primaryBlue};
  }

  &:hover {
    background: transparent;
  }
`;
