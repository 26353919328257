import styled from 'styled-components';

export const AdvantagesContainer = styled.div`
  background: ${({ theme }) => theme.primaryMainBlueBG};
  height: auto;
  width: 100%;
  padding: 72px 0;

  p{
    color: ${({ theme }) => theme.primaryGrayWhite};
    padding: 10px 0;
  }
`

export const AdvantagesSmallTitle = styled.div`
  margin-bottom: 15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryGrayWhite};
  text-align: center;
`

export const AdvantagesTitle = styled.h2`
  text-align: center;
  color: #EEFBFB; 

  &:after {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;

  }
`