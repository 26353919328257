import React from 'react';
import { bool, func } from 'prop-types';
import { Burger } from './styled';

const BurgerWrapper = ({ open, setOpen }) => {
  return (
    <Burger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </Burger>
  )
};

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default BurgerWrapper;