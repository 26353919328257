import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import HomePage from './pages/home';
import ContactPage from './pages/contact';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'; 
import ScrollToTop from "./hooks/ScrollToTop";
import { GlobalStyle } from './globalStyle';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle/>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path='/' component={HomePage} exact />
          <Route path='/kontakt' component={ContactPage} exact />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
