import styled from "styled-components";
import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
  ${normalize}

  html, body {
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    color: #373f42;
    background: ${({ theme }) => theme.primaryBackground};
  }

  h2 {
    font-size: 38px;
    color: ${({ theme }) => theme.primaryMainBlue};
    margin: 0;
    padding-bottom: 10px;
    margin: 0 0 14px 0;
    position: relative;
    letter-spacing: -0.02em;
    text-align: center;

  &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 150px;
    border-bottom: 2px solid ${({ theme }) => theme.primaryBlue};
    }
  }

  h3 {
    margin: 0;
    font-size: 23px;
    font-weight: 500;
    padding-bottom: 5px;
    position: relative;
  }

  p {
    letter-spacing: 0.015em;
    word-spacing: 0.001em;
    line-height: 1.5;
    margin: 0;
    font-size: 16px;
    color: ${({ theme }) => theme.primaryTextColor};
  }
`;

export const AppContainer = styled.div`
  max-width: 1200px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  background-color: gray;
`;
