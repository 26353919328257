import React from "react";
import AppContainer from "../Container";
// import { Swiper, SwiperSlide } from "swiper/react";
import {
  ProductsContainer,
  ProductsSmallTitle,
  ProductsTitle,
  ProductWrapper,
  ProductTitle,
  ProductInfo,
  ProductImg,
  ProductSlider,
  BtnWrapper,
  ProductPDF,
} from "./styled";
import { Certificate } from "@styled-icons/fluentui-system-filled/Certificate";
import { DocumentPdf } from "@styled-icons/fluentui-system-regular/DocumentPdf";
import { CheckCircle } from "@styled-icons/boxicons-solid/CheckCircle";

const Products = ({ ...products }) => {
  const productsRender = Object.values(products).map((product) => (
    <ProductWrapper id={product.id}>
      <ProductSlider>
        <ProductImg src={product.img1} alt={product.alt} />
      </ProductSlider>
      <ProductInfo>
        <ProductTitle>{product.name}</ProductTitle>
        <p>{product.description}</p>
        <ul>
          <li>
            <p>
              <CheckCircle />
              {product.description2}
            </p>
          </li>
          <li>
            <p>
              <CheckCircle />
              {product.description3}
            </p>
          </li>
          <li>
            <p>
              <CheckCircle />
              {product.description4}
            </p>
            {product.description5 ? (
              <p>
                <CheckCircle />
                {product.description5}
              </p>
            ) : null}
          </li>
        </ul>
        <BtnWrapper>
          {product.raport ? (
            <ProductPDF
              href={product.raport}
              without
              rel="noopener noreferrer"
              target="_blank"
            >
              <DocumentPdf title="Raport PDF" />
              Raport
            </ProductPDF>
          ) : null}
          {product.certyfikat ? (
            <ProductPDF
              href={product.certyfikat}
              without
              rel="noopener noreferrer"
              target="_blank"
            >
              <Certificate title="Certyfikat PDF" />
              Certyfikat
            </ProductPDF>
          ) : null}
          {product.manual ? (
            <ProductPDF
              href={product.manual}
              without
              rel="noopener noreferrer"
              target="_blank"
            >
              <DocumentPdf title="Manual PDF" />
              Manual
            </ProductPDF>
          ) : null}
          {product.cyto ? (
            <ProductPDF
              href={product.cyto}
              without
              rel="noopener noreferrer"
              target="_blank"
            >
              <DocumentPdf title="Cytotoksyczność PDF" />
              Cytotoksyczność
            </ProductPDF>
          ) : null}
          {product.ce ? (
            <ProductPDF
              href={product.ce}
              without
              rel="noopener noreferrer"
              target="_blank"
            >
              <DocumentPdf title="Deklaracja zgodności PDF" />
              Deklaracja zgodności
            </ProductPDF>
          ) : null}
        </BtnWrapper>
      </ProductInfo>
    </ProductWrapper>
  ));

  return (
    <AppContainer>
      <ProductsContainer name="products">
        <ProductsSmallTitle>Nasze produkty</ProductsSmallTitle>
        <ProductsTitle>Gwarantujemy wysoką jakość i&nbsp;ochronę</ProductsTitle>
        {productsRender}
      </ProductsContainer>
    </AppContainer>
  );
};

export default Products;
