import React from "react";
import AppContainer from "../Container";
import {
  QualityContainer,
  QualityContent,
  QualityTitleSmall,
  QualityTitle,
  TextWrapper,
  QualityText,
  QualityGallery,
  NumbersContainer,
  PromoWrapper,
  PromoLine,
  VideoContainer,
  QualityVideo,
} from "./styled";
import { PackageIcon } from "@styled-icons/boxicons-regular/PackageIcon";
import { Timer } from "@styled-icons/boxicons-regular/Timer";
import { Handshake } from "@styled-icons/fa-solid/Handshake";

import production1 from "../../assets/prod1.jpg";
import production2 from "../../assets/prod2.jpg";
import production3 from "../../assets/prod3.jpg";

import prodVideo from "../../assets/prodVideo.mp4";
import videoPoster from "../../assets/videoPoster.jpg"

const Quality = () => (
  <QualityContainer>
    <AppContainer>
      <QualityContent>
        <QualityText>
          <QualityTitleSmall>Nasza jakość</QualityTitleSmall>
          <QualityTitle>
            Innowacyjna fabryka dba o&nbsp;produkt dla&nbsp;Ciebie
          </QualityTitle>
          <TextWrapper>
            <p>
              Produkcja masek medycznych typu II odbywa się w strefach o
              podwyższonym standardzie higienicznym, spełniających kryteria
              czystości pyłowej i mikrobiologicznej klasy ISO 9 zgodnie z PN-EN
              ISO 14644:2016.
            </p>
            <p>
              Posiadamy specjalnie opracowany i wdrożony system zapewnienia i
              zarządzania jakością, PN-EN ISO 13485:2016 oraz PN-EN ISO
              9001:2015, w tym procedury SOP i dokumentację techniczną,
              gwarantujące spełnienie przez producenta najwyższych standardów
              jakości dla produkcji wyrobów medycznych oraz środków ochrony
              indywidualnej.
            </p>
          </TextWrapper>
        </QualityText>
        <QualityGallery>
          <img src={production1} alt="Production"></img>
          <img src={production2} alt="Production"></img>
          <img src={production3} alt="Production"></img>
        </QualityGallery>
      </QualityContent>
      <NumbersContainer>
        <PromoWrapper>
          <Timer />
          <p>szybka realizacja zamówienia</p>
        </PromoWrapper>
        <PromoLine />
        <PromoWrapper>
          <PackageIcon />
          <p>wysokie stany magazynowe</p>
        </PromoWrapper>
        <PromoLine />
        <PromoWrapper>
          <Handshake />
          <p>indywidualny charakter współpracy</p>
        </PromoWrapper>
      </NumbersContainer>
      <VideoContainer>
        <QualityVideo
          muted="true"
          controls="controls"
          controlsList="nodownload"
          playsInline
          loop
          disablePictureInPicture
          disableRemotePlayback
          preload="none"
          src={prodVideo}
          poster={videoPoster}
          type="video/mp4"
        />
      </VideoContainer>
    </AppContainer>
  </QualityContainer>
);

export default Quality;
