import styled from 'styled-components';
import {
  Link as LinkScroll
} from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';

export const SidebarMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.primaryBackground};
  height: 100vh;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  text-align: right;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
  border-radius: 15px 0 0 15px;
  width: 60%;
`;

export const SidebarLink = styled(LinkScroll)`
  display: block;
  text-align: center;
  line-height: 50px;
  font-size: 1.5rem;
  text-decoration: none;
  color: rgba(7,5,138,0.7);
  cursor: pointer;
  letter-spacing: 1px;

  &:before {
    content: '';
    display: table;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  }

  &:hover {
    color: ${({ theme }) => theme.primaryMainBlue};
  }
`

export const SidebarRoute = styled(LinkR)`
  display: block;
  font-size: 1.5rem;
  text-align: center;
  line-height: 50px;
  font-weight: bold;
  text-decoration: none;
  color: rgba(7,5,138,0.7);
  cursor: pointer;
  letter-spacing: 1px;

  &:hover {
    color: ${({ theme }) => theme.primaryMainBlue};
  }

  &:before {
    content: '';
    display: table;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  }

  &:after {
    content: '';
    display: table;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`