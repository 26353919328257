import styled, { css } from "styled-components";
import contactBG from "../../assets/contactBG.jpg";

const sharedStyle = css`
  border: none;
  outline: none;
  border-bottom: 1px solid #9da2b3;
  margin: 10px 0 0px 0;
  font-family: inherit;
  width: 100%;
  font-size: 1.3rem;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  border-radius: 0;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: transparent;
  }
  &:-ms-input-placeholder {
    color: transparent;
  }

  &:focus {
    padding-bottom: 6px;
    font-weight: 600;
    border-width: 2px;
    border-image-slice: 1;
    border-color: ${({ theme }) => theme.primaryMainBlue};
    background: transparent;
  }

  &:valid {
    border-color: ${({ theme }) => theme.primaryMainBlue};
  }
`;

export const ContactContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 273px); // minus footer height
`;

export const ContactBgImage = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 650px;
  background-image: url(${contactBG});
  background-attachment: scroll;
  background-size: 900px;
  background-repeat: no-repeat;
  
  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    background-attachment: fixed;
    background-size: 100% 650px;
    background-size: cover;
  }
`;

export const ContactTitle = styled.h1`
color: ${({ theme }) => theme.primaryWhite};
font-size: 45px;
margin: 150px 24px 50px 24px;
z-index: 1;
text-align: center;

@media screen and (min-width: ${({ theme }) => theme.mobile}) {
    margin: 200px 24px 100px 24px;
  }
`;

export const ContactWrapper = styled.div`
  background: #000046;
  padding: 15px;
  margin: 0 15px 39px 15px;
  border-radius: 15px;
  width: auto;
  max-width: 960px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 2;
 

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    flex-direction: row;
    margin: 39px;
  }
  @media screen and (min-height: 1366px) {
    margin-top: 100px;
  }
  @media screen and ( min-width: 3860px ) and ( min-height: 2160px ) {
    transform: scale(1.5);
    margin-top: 300px;
  }

`

export const FormContainer = styled.form`
  background-color: ${({ theme }) => theme.primaryWhite};
  max-width: 500px;
  padding: 36px;
  border-radius: 5px;
  
  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    min-width: 300px;
  }

  @media screen and (max-width: ${({ theme }) => theme.mobile}) {
    padding: 24px;
  }
`;

export const FormWrapper = styled.div`
  position: relative;
`;

export const FormTitle = styled.h2`
  margin: 0 0 24px 0;
`;

export const FormInput = styled.input.attrs(() => ({
  required: true,
}))`
  min-width: 190px;

  ${sharedStyle}
`;

export const FormLabel = styled.label`
  position: absolute;
  top: 20px;
  display: block;
  transition: 0.2s;
  font-size: 1.2rem;
  pointer-events: none;
  color: #9da2b3;

  input:focus ~ &,
  textarea:focus ~ &,
  input:not(:placeholder-shown).input:not(:focus) ~ &,
  textarea:not(:placeholder-shown).textarea:not(:focus) ~ &,
  input:valid ~ &,
  textarea:valid ~ & {
    color: ${({ theme }) => theme.primaryMainBlue};
    top: 0;
    font-size: 0.8rem;
  }
`;

export const FormMessageInput = styled.textarea.attrs(() => ({
  required: true,
}))`
  width: 100%;
  height: 60px;
  resize: none;

  ${sharedStyle}
`;
export const ErrorMessage = styled.div`
  color: red;
  height: 16px;
  p {
    font-size: 11px;
  }
`;

export const FormBtn = styled.button`
  background: ${({ theme }) => theme.primaryBlue};
  color: #ffffff;
  display: block;
  height: 50px;
  width: 240px;
  border: 1px solid ${({ theme }) => theme.primaryBlue};
  border-radius: 25px;
  cursor: pointer;
  margin: 10px auto 0 auto;
  font-size: 16px;

  &:hover {
    background: transparent;
    color: ${({ theme }) => theme.primaryBlue};
  }
`;

export const RodoContainer = styled.div`
  font-size: 10px;
  input {
    margin: 0 10px 0 0;
    size: 20px;
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
  padding: 36px;
  max-width: 360px;

  @media screen and (max-width: ${({ theme }) => theme.mobile}) {
    padding: 24px;
    margin: 0 auto;
  }
`;

export const IconStyleWrapper = styled.div`
  height: 24px;
  width: 24px;
  margin: 0 15px 0 0;
  color: ${({ theme }) => theme.primaryBlue};
`;

export const InfoDescription = styled.div`

  h3 {
    text-align: center;
    padding-bottom: 24px;

    &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    width: 100%;
    border-bottom: 2px solid ${({ theme }) => theme.primaryBlue};
    }

    @media screen and (min-width: ${({ theme }) => theme.mobile}) {
      text-align: left;
    }
  }

`;

export const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  p {
    margin: 20px 0;
    color: ${({ theme }) => theme.primaryGrayWhite}
    
  }

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    p{
      margin-top: 0;
    }
  }
`;

export const ContactsLine = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  color: ${({ theme }) => theme.primaryGrayWhite};
  text-decoration: none;

  &:hover{
    color: ${({ theme }) => theme.primaryWhite};
  }
`;


