import styled from "styled-components";

export const QualityContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const QualityContent = styled.div`
  display: flex;
  margin: 36px 0;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const QualityText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    width: 45%;
  }
`;

export const QualityTitleSmall = styled.div`
  margin-bottom: 15px;
  text-transform: uppercase;
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    text-align: left;
  }
`;

export const QualityTitle = styled.h2`
  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    text-align: left;

    &:after {
      right: auto;
      text-align: left;
    }
  }
`;

export const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  p {
    padding: 10px 0;
  }
`;

export const QualityGallery = styled.figure`
  width: 98%;
  display: grid;
  grid-template-rows: 35% 29% 29%;
  grid-template-columns: 10fr 26fr 55fr 26fr;
  grid-gap: 0 1.5rem;
  margin: 24px 0;

  img {
    object-fit: contain;
    width: 100%;
    box-shadow: 0 4px 13px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: 5px solid white;

    &:first-child {
      grid-column: 2/4;
      grid-row: 1;
      object-position: center right;
    }

    &:nth-child(2) {
      grid-column: 1/3;
      grid-row: 2;
    }

    &:nth-child(3) {
      grid-column: 3/5;
      grid-row: 2/3;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    width: 600px;
    grid-template-rows: 160px 150px 80px;
    margin: auto 36px;
  }
`;

export const NumbersContainer = styled.div`
  height: auto;
  width: 70%;
  background-color: ${({ theme }) => theme.primaryMainBlueBG};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto 76px auto; //remove this if grid is corretly
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.25);

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    padding: 72px 36px;
    width: 75%;
  }

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    flex-direction: row;
    margin: 96px auto 76px auto;
    width: 90%;
    
  }
`;

export const PromoWrapper = styled.div`
  height: auto;
  color: ${({ theme }) => theme.primaryWhite};
  position: relative;
  padding: 15px 0;

  svg {
    display: block;
    margin: 0 auto;
    height: 65px;
    width: 65px;
  }

  p {
    text-transform: uppercase;
    display: flex;
    text-align: center;
    width: 230px;
    font-weight: 300;
    font-size: 1em;
    margin: 10px auto 0 auto;
    color: ${({ theme }) => theme.primaryGrayWhite};
  }

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0;
  }
`;

export const PromoLine = styled.div`
  height: 2px;
  width: 80px;
  background: ${({ theme }) => theme.primaryBlue};

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    width: 2px;
    height: 80px;
  }
`;

export const VideoContainer = styled.div`
  margin: 3em 0;
  display: flex;
  justify-content: center;
`;

export const QualityVideo = styled.video`
  box-shadow: -10px 10px 80px rgb(0 0 0 / 10%);
  border: 10px solid #fff;
  border-radius: 20px;
  width: 100%;
`;
