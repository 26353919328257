import ply from '../../assets/3ply.png';
import ffp2img from '../../assets/ffp2.png';
import raportTypII from "../../assets/raportMaskaMedyczna.pdf";
import certificateFFP2 from "../../assets/certyfikatFFP2.pdf";
import ceTypII from "../../assets/ceMaskaMedyczna.pdf";
import ceFFP2 from "../../assets/ceFFP2.pdf";
import manual from "../../assets/manualFFP2.pdf";
import cyto from "../../assets/cytotoksyczność.pdf";

export const productsData = {
  type1: {
    id: 1,
    name: 'Maska medyczna typ II',
    img1: ply,
    cyto: cyto,
    raport: raportTypII,
    ce: ceTypII,
    alt: 'Maska typ II',
    description: 'To jednorazowy wyrób medyczny przeznaczony dla personelu medycznego. Redukuje ona ryzyko zakażeń przenoszonych drogą kropelkową podczas wykonywania badań.',
    description2: 'Posiada wysoką skuteczność filtracji bakterii BFE na poziomie ≥ 98%',
    description3: 'Maska Medyczna Typ II jest całkowicie bezpieczna, dowodem na to są wykonane badania cytotoksyczności, drażnienia i uczulenia.',
    description4: 'Produkowane są w strefach o podwyższonym standardzie higienicznym, zgodnie z wymaganiami norm PN-EN ISO 13485:2016 oraz PN-EN 14683+AC:2019, w celu zapewnienia odpowiedniego poziomu czystości mikrobiologicznej.', 
    description5: 'Produkt konfekcjonowany w opakowaniach po 20 lub 50\u00a0szt.'
  },
  type2: {
    id: 2,
    name: 'Półmaska filtrująca FFP2 NR',
    img1: ffp2img,
    certyfikat: certificateFFP2,
    ce: ceFFP2,
    manual: manual,
    alt: 'Maska FFP2',
    description: 'Została wyprodukowana dla zapewnienia obustronnej filtracji powietrza wdychanego i wydychanego przed cząsteczkami pyłów, cieczy i aerozoli.',
    description2: 'Półmaska filtrująca FFP2 NR spełnia wymagania normy zharmonizowanej EN 149:2001+41:2009.',
    description3: 'Skuteczność co najmniej 95% zanieczyszczeń pyłowych o wielkości 0,3 μm, a także cząstki o większej średnicy (drobne pyłki) i cząsteczki płynów (aerozole).',
    description4: 'Produkt konfekcjonowany w\u00a0opakowaniach po\u00a010 sztuk.', 
  },
};
