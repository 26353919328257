import React from 'react';
import Navbar from '../components/Navbar';
import ContactForm from '../components/Contact';
import Footer from '../components/Footer';

const Contact = () => {
  return(
    <>
      <Navbar />
      <ContactForm />
      <Footer />
    </>
  )
}

export default Contact;