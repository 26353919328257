import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const FooterContainer = styled.footer`
  height: auto;
  width: 100%;
  padding: 36px 0 0 0;
  background-color: ${({ theme }) => theme.primaryMainBlueBG};

  p {
    display: block;
    font-size: 16px;
    white-space: nowrap;
    color: ${({ theme }) => theme.primaryGrayWhite};
  }
`;
export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.mobile}) {
  flex-direction: row;
  }
`;

export const FooterContactTitleWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto 0;
`;

export const FooterContactTitle = styled.h2`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 30px;
  margin: 0 0 24px 0;
  text-align: left;

  &:after {
      right: auto;
      text-align: left;
    }
`;

export const PageBtn = styled(LinkR)`
  border-radius: 25px;
  text-decoration: none;
  margin: 0 10px;
  padding: 10px 20px;
  width: 110px;
  height: 100%;
  cursor: pointer;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primaryBlue};

  &:hover {
    color: #ffffffa6;
    box-shadow: inset 0 0 0 1px #007cc79e;
  }
`;
export const FooterAddress = styled.div`
  color: ${({ theme }) => theme.primaryGrayWhite};
  white-space: nowrap;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 36px;

  h3 {
    margin-bottom: 10px;

    &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    width: 100%;
    border-bottom: 2px solid ${({ theme }) => theme.primaryBlue};
    }
  }

  p {
    color: #fff;
    line-height: 1.8;
  }

  @media (min-width: ${({ theme }) => theme.mobile}) {
    display: none;
  }
  @media (min-width: ${({ theme }) => theme.tablet}) {
    margin-top: 0;
    display: flex;
  }
  
`;

export const FooterContact = styled.div`
  color: ${({ theme }) => theme.primaryGrayWhite};
  margin-top: 36px;

  h3 {
    margin-bottom: 10px;

    &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    width: 100%;
    border-bottom: 2px solid ${({ theme }) => theme.primaryBlue};
    }
  }

  @media (min-width: ${({ theme }) => theme.mobile}) {
    margin-top: 0;
  }
`;

export const FooterContactsLine = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.primaryWhite};
  text-decoration: none;
  padding: 5px 0;
  line-height: 1.3;

  &:hover {
    color: ${({ theme }) => theme.primaryGrayWhite};
  }
`;

export const IconStyleWrapper = styled.div`
  height: inherit;
  width: 24px;
  vertical-align: middle;
  margin: auto 10px auto 0;
  color: ${({ theme }) => theme.primaryBlue};
`;

export const Copyright = styled.div`
  text-align: center;
  height: auto;
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.primaryMainBorder};
  background: ${({ theme }) => theme.primaryMainBlueBG};
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: 200;
  font-size: 14px;
  margin-top: 36px;
`;