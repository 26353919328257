import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

import logo from "../../assets/logopolanex.png";
import logoShort from "../../assets/polanex.png";

export const Nav = styled.nav `
  background: ${({ isScrolled, theme }) => (isScrolled ? theme.primaryBackground : 'transparent')};
  border-bottom: 1px solid ${({ isScrolled, theme }) => (isScrolled ? theme.primaryBorder : 'transparent')};
  height: ${({ isScrolled }) => (isScrolled ? '70px' : '150px')};
  box-shadow: ${({ isScrolled }) => (isScrolled ? 'rgb(0 0 0 / 25%) 0px 4px 13px': 'none')};
  width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  letter-spacing: 0.04em;
  position: fixed;
  top: 0;
  z-index: 10;
  transition: 0.8s all ease;
`;

export const NavbarConteiner = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
  cursor: pointer;
  display: flex;
  margin: auto 0;
  text-decoration: none;
  height: ${({ isScrolled }) => (isScrolled ? '70px' : '110px')};
  width: 150px;
  background-image: ${({ isScrolled }) => (isScrolled ? `url(${logoShort})` : `url(${logo})`)};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
`;

export const MobileIcon = styled.div `
  display: block;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    display: none;
  }
`;

export const NavMenu = styled.ul `
  display: none;
  list-style: none;
  height: 38px;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    display: flex;
  }
`;

export const NavMenuContact = styled.ul `
  list-style: none;
  height: 38px;
`

export const NavItem = styled.li `
  height: 100%;
`;

export const NavLink = styled(LinkS) `
  display: flex;
  color: ${({ isScrolled, theme }) => (isScrolled ? 'rgba(7,5,138,0.7)' : theme.primaryWhite)};
  align-items: center;
  text-decoration: none;
  margin: 0 10px;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: ${({ isScrolled, theme }) => (isScrolled ? theme.primaryMainBlue : theme.primaryGrayWhite)};
  }

  &.active{
    color: ${({ theme }) => theme.primaryMainBlue};
    box-shadow: inset 0 -11px 0 -10px ${({ theme }) => theme.primaryMainBlue};
  }
`;

export const NavLinkRoute = styled(LinkR) `
  display: flex;
  color: ${({ theme }) => theme.primaryWhite};
  background: ${({ theme }) => theme.primaryMainBlue};
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  list-style: none;
  margin: 0 0 0 10px;
  width: 110px;
  height: 100%;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primaryMainBlue};
  transition: 0.3s;

  &:hover {
    color: ${({ isScrolled, theme }) => (isScrolled ? theme.primaryMainBlue : theme.primaryMainWhite)};
    background: transparent;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primaryMainBlue};
  }
`;

export const NavBtn = styled.nav `
  display: none;
  align-items: center;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    display: flex;
  }
`;

export const NavBtnLang = styled.div `
  color: #ffff;
`;