import React from 'react';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import About from '../components/About';
import Quality from '../components/Quality';
import Advantages from '../components/Advantages';
import Products from '../components/Producs';
import Footer from '../components/Footer';

import { productsData } from '../components/InfoDescryption/ProductsData';



const Home = () => {
  return(
    <>
      <Navbar />
      <Header />
      <About />
      <Quality />
      <Advantages />
      <Products {...productsData}/>
      <Footer />
    </>
  )
}

export default Home;