import React from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";

import {
  ContactContainer,
  ContactBgImage,
  ContactTitle,
  ContactWrapper,
  FormContainer,
  FormWrapper,
  FormTitle,
  FormInput,
  FormLabel,
  FormMessageInput,
  ErrorMessage,
  RodoContainer,
  FormBtn,
  ContactInfo,
  InfoDescription,
  Contacts,
  ContactsLine,
  IconStyleWrapper,
} from "./styled";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { PhoneAlt } from "@styled-icons/fa-solid/PhoneAlt";
import { Mail } from "@styled-icons/heroicons-solid/Mail";
import { Location } from "@styled-icons/entypo/Location";

// init("user_gmloMNsceGVuKVahM73NS");

const ContactForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const { name, email, phone, message } = data;
    const dataToSubmit = {
      name,
      email,
      phone,
      message,
    };

    emailjs
      .send(
        "default_service",
        "template_heal3yp",
        dataToSubmit,
        "user_gmloMNsceGVuKVahM73NS"
      )
      .then(
        function (response) {
          toast.success("Wiadomość została wysłana!");
        },
        function (error) {
          toast.error("Problem z wysłaniem wiadomości");
        }
      )
      .then(() => {
        reset();
      });

    // await fetch("https://api.emailjs.com/api/v1.0/email/send", {
    //   method: "POST",
    //   headers: {
    //     "Content-type": "application/json",
    //     'Accept': 'application/json'
    //   },
    //   body: JSON.stringify({ dataToSubmit }),
    // })
    //   .then((res) => res.json())
    //   .then(async (res) => {
    //     const resData = await res;
    //     if (resData.status === "success") {
    //       toast.success("Wiadomość została wysłana!");
    //     } else if (resData.status === "fail") {
    //       toast.error("Problem z wysłaniem wiadomości");
    //     }
    //   })
    //   .then(() => {
    //     reset();
    //   });
  };

  return (
    <ContactContainer>
      <ToastContainer />
      <ContactBgImage />
      <ContactTitle>Masz jakieś pytania?</ContactTitle>
      <ContactWrapper>
        <FormContainer onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormTitle>Wyślij nam wiadomość</FormTitle>
          <FormWrapper>
            <FormInput
              type="text"
              name="name"
              {...register("name", {
                required: {
                  value: true,
                  message: "Wprowadź swoje imię i nazwisko",
                },
              })}
            />
            <FormLabel htmlFor="name">Imię i Nazwisko</FormLabel>
          </FormWrapper>
          <ErrorMessage>
            {errors.name && <p>{errors.name.message}</p>}
          </ErrorMessage>
          <FormWrapper>
            <FormInput
              type="tel"
              name="phone"
              {...register("phone", {
                required: {
                  value: true,
                  message: "Wprowadź swój numer telefonu",
                },
              })}
            />
            <FormLabel htmlFor="phone">Telefon</FormLabel>
          </FormWrapper>
          <ErrorMessage>
            {errors.phone && <p>{errors.phone.message}</p>}
          </ErrorMessage>
          <FormWrapper>
            <FormInput
              type="text"
              name="email"
              {...register("email", {
                required: true,
                pattern:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
            />
            <FormLabel htmlFor="email">Email</FormLabel>
          </FormWrapper>
          <ErrorMessage>
            {errors.email && <p>Proszę wprowadź swój email</p>}
          </ErrorMessage>
          <FormWrapper>
            <FormMessageInput
              name="message"
              {...register("message", {
                required: true,
              })}
              placeholder="Jak możemy Ci pomóc?"
            />
            <FormLabel htmlFor="message">Wiadomość</FormLabel>
          </FormWrapper>
          <ErrorMessage>
            {errors.message && <p>Proszę wpisz treść wiadomości</p>}
          </ErrorMessage>
          <RodoContainer>
            <input
              name="acceptTerms"
              type="checkbox"
              {...register("acceptTerms", {
                required: true,
              })}
            />
            "Informujemy, że Administratorem Twoich danych osobowych jest
            POLANEX Sp. z o.o. przy ul. Słonecznej 40 z siedzibą w 62-200
            Gniezno. Dane osobowe są przetwarzane zgodnie z przepisami
            Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
            27 kwietnia 2016 r. Dane wpisane w formularzu kontaktowym, będą
            przetwarzane tylko w celu udzielenia odpowiedzi na przesłane
            zapytanie. Usunięcie Twoich danych jest możliwe w każdym momencie
            bez podania przyczyny"
          </RodoContainer>
          <ErrorMessage>
            {errors.acceptTerms && <p>Akceptacja regulaminu jest wymagana</p>}
          </ErrorMessage>
          <FormBtn type="submit">Wyślij</FormBtn>
        </FormContainer>
        <ContactInfo>
          <InfoDescription>
            <h3>
              Chciałbyś dowiedzieć się więcej o&nbsp;naszej ofercie? Skorzystaj
              z&nbsp;formularza, aby&nbsp;wysłać nam wiadomość.
            </h3>
          </InfoDescription>
          <Contacts>
            <p>
              <b>Jesteśmy do Twojej dyspozycji:</b>
              <br /> Poniedziałek - Piątek 9:00 - 17:00
            </p>
            <ContactsLine href="tel:+48790208029">
              <IconStyleWrapper>
                <PhoneAlt title="Phone" />
              </IconStyleWrapper>
              +48 790 208 029
            </ContactsLine>

            <ContactsLine href="mailto:sekretariat@polanex.com.pl">
              <IconStyleWrapper>
                <Mail title="Mail" />
              </IconStyleWrapper>
              sekretariat@polanex.com.pl
            </ContactsLine>
          </Contacts>
        </ContactInfo>
      </ContactWrapper>
    </ContactContainer>
  );
};

export default ContactForm;
