export const theme = {
  primaryBlue: '#007aff',
  primaryWhite: '#fff',
  primaryGrayWhite: '#ffffffA6',
  primaryBackground: '#f8f8f8',
  primaryBorder: 'rgba(0,0,0,.1)',
  primaryMainBlue: '#07058B',
  primaryMainBlueBG: '#000046',
  primaryTitleBlue: '#2F3857',
  primaryTextColor: '4F5772',
  primaryGray: '#9da2b3',
  mobile: '576px',
  tablet: '768px',
  desktop: '960px',
}