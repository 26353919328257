import React from "react";
import { AdvantagesContainer, AdvantagesSmallTitle, AdvantagesTitle } from "./styled";
import AppContainer from "../Container";

const Advantages = () => (
  <>
    <AdvantagesContainer>
      <AppContainer>
        <AdvantagesSmallTitle>Co nas wyróżnia</AdvantagesSmallTitle>
        <AdvantagesTitle>Zapewniamy wysokie standardy higieniczne</AdvantagesTitle>
        <p>
          Produkujemy na szeroką skalę i zaopatrujemy największe instytucje
          państwowe w naszym kraju, szpitale, szkoły, urzędy i inne miejsca
          użyteczności publicznej. Każdego dnia wysyłamy setki tysięcy masek
          medycznych do odbiorców, którzy sprawdzili nasze produkty, zaufali nam
          i podtrzymują swój wybór.
        </p>
        <p>
          Naszym nadrzędnym celem jest przygotowanie produktu, który pozwoli
          zabezpieczyć nas wszystkich – nie tylko w miejscach przeznaczenia
          medycznego, ale we wszystkich lokalizacjach, w których kontakt z
          drugim człowiekiem stanowi podstawę funkcjonowania. Aby zapewnić
          możliwie jak największy komfort noszenia oraz najwyższe
          bezpieczeństwo, proponujemy maseczki medyczne, posiadające wysokie
          standardy higieniczne i odpowiednią certyfikację.
        </p>
      </AppContainer>
    </AdvantagesContainer>
  </>
);

export default Advantages;
