import styled from "styled-components";

export const AboutContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const AboutTitleSmall = styled.div`
  margin-bottom: 15px;
  text-transform: uppercase;
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    display: block;
  }
`;
export const AboutTitle = styled.h2`
  text-align: left;
  display: none;

  &:after {
    right: auto;
    text-align: left;
  }

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    display: block;
  }
`;

export const AboutTitleSmallMobile = styled.div`
  text-align: center;
  margin-bottom: 15px;
  text-transform: uppercase;

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    display: none;
  }
`;

export const AboutTitleMobile = styled.h2`
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    display: none;
  }
`;

export const AboutContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 7em 0;
  flex-direction: column;
  width: 100%;

  img {
    object-fit: cover;
    width: 90%;
    height: 100%;
    box-shadow: -10px 10px 80px rgb(0 0 0 / 10%);
    border: 10px solid ${({ theme }) => theme.primaryWhite};
    border-radius: 20px;
    margin: 0 auto;
  }

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    width: auto;
    flex-direction: row;

    img {
      width: 45%;
      margin: 0;
    }
  }
`;

export const AboutText = styled.div`
  width: 100%;
  margin-top: 2rem;

  p {
    margin: auto 0;
  }

  a {
    text-decoration: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.desktop}) {
    width: 45%;
  }
`;
